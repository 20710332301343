<template>
  <div id="itemlist">
    <a-row v-if="item.length>0" type="flex" justify="center">
      <a-col v-for="data in item" :key="data.id">
        <b-card
          :title="getxt(data.actitle)"
          tag="article"
          style="margin: 10px;max-width :23rem;border-radius:15px; box-shadow: 1px 5px 6px #f3f5f6;"
        >
          <b-card-text>
            活動起訖 :
            <span v-text=" fdateformat(data)" />
            <b-button @click="showDetail(data.acts_signform)" size="sm" variant="link">(參加明細)</b-button>
          </b-card-text>
          <b-card-img :src="data.url!==''? data.url:mainImg('event_1080x540.png')" alt="Image" style="border-radius:10px;" bottom></b-card-img>
        </b-card>
      </a-col>
    </a-row>
    <b-modal
      ref="my-list"
      centered
      @hide="onHide"
      id="modDetail"
      title
      hide-header
      hide-footer
    >
      <div class="text-center" id="cbodyer">
        <div>
          <div class="qrheader">
            <span v-text="'關閉[X]'" @click="toclose()" style="cursor: pointer;" />
          </div>
          <div class="qrbody" v-if="rsData!==null">    

            <div class="list-group-item" :id="'word' + element.id" style="padding:3px;margin:2px;max-height:160px;" v-for="(element, index) in rsData.acts_signdetail" :key="element.id">                 
                              <div class="container">
                                <div class="row" >                                  
                                  <hr v-if="index==0" class="hr-text" :data-content="formatDate(element.sdate)">
                                  <hr v-if="index>0 && (rsData.acts_signdetail[index-1].sdate !==rsData.acts_signdetail[index].sdate)" class="hr-text" :data-content="formatDate(element.sdate)">                                    
                                  <div class="text-left" style="padding:1px;margin:1px;font-size: 14px;" v-text="'課程時間 :' + element.sdate +'~' + element.edate " />                          
                                  <div class="text-left" style="padding:1px;margin:1px;font-size: 14px;" v-text="'姓名 : '+element.username+' | 角色 : ' + getRole(element.roles) +' | 分數:'+element.score" />
                                  <div class="text-left" style="padding:1px;margin:1px;font-size: 14px;">
                                  <span v-text="'報到 : '+(element.states=='Y'?'✅已報到 | 報到時間 :'+formatDateTime(element.arrival):'⭕未報到')" />
                                  <b-button v-if="element.states!=='Y' && element.sdate >getdate(currentTime)" variant="link" @click="delItem(element,element.roles)" size="sm">
                                    <b-icon icon="trash" aria-hidden="true"></b-icon>取消該課程
                                  </b-button>
                                  </div>                                                                    
                                </div>
                              </div>                                                                
                  </div>  
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { actService } from "../../_services";

const deleteItemam = params => {
  return actService.delMan(params.id, params.aid,params.fid, params.uid, params.roles);
};

export default {
  name: "Viewper",
  props: {
    msg: String,
    item: Array,
    winsize: Object
  },
  data() {
    return {
      myJson: [],
      rsData: null,
      currentTime:moment(),
    };
  },
  methods: {
    fdateformat(val) {
      return (
        moment(val.sdate).format("YYYY-MM-DD") +
        "~" +
        moment(val.edate).format("MM-DD")
      );
    },

    sortRS(objs){
      return objs.sort((a,b) => (a.sdate > b.sdate))
    },

    mainImg(imgs) {
      return require(`../../assets/${imgs}`);
    },     

    delItem(items,roles) {
      const self = this;
      this.$confirm({
        title:
          "您確定刪除該課程?",
        content: "活動名稱 : " + items.actitle + '|時間:'+items.sdate,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        zIndex: 9999,
        async onOk() {
          await self.removem(items,roles);
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },

    async removem(items,roles) {
      await deleteItemam({ id: items.cid, aid: items.aid, fid : items.fid, uid: items.uid,roles }).then(
        ({ data }) => {
          data.code == 200
            ? this.reloads(items)
            : this.$message.warning("刪除發生錯誤");
            console.log(data)
        }
      );
    },

    reloads(item){
      let rs= this.rsData.acts_signdetail.filter((el)=> { return el.id !== item.id});
      this.rsData.acts_signdetail =rs
      this.$emit("upreloads",this.rsData)
    },

    getdate(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },

    catImg(imgs) {
      return require(`../../assets/img/${imgs}`);
    },

    getxt(val) {
      return val.substring(0, 32);
    },

    showDetail(item) {
      item.acts_signdetail = item.acts_signdetail.sort((a, b) => {
            let da = new Date(a.sdate),
                db = new Date(b.sdate);
            return da - db;
        })
      this.rsData = item;
      this.$refs["my-list"].show();
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    toclose() {
      this.$refs["my-list"].hide();
    },
    getRole(val){
      let tmp=""
      val=="actor"? tmp="參加者":"";
      val=="broker"? tmp="主辦單位":"";
      val=="coach"? tmp="講師":"";
      val=="volunteer"? tmp="志工":"";
      val=="D"? tmp="下架":"";
      return tmp
    }, 
    
    formatDate(val){
        return moment(val).format("YYYY-MM-DD"); 
    },   
    formatDateTime(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    }, 
    shorts(rs){
      return rs.sort((a,b)=>(a.sdate-b.sdate))
    }          
  },
  created() {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#itemlist {
  margin: 0px auto;
  padding: 0px;
  max-width: 1024px;
}
.card-title {
  text-align: center;
  font-weight: bold;
  margin: 0;
  font-size: 13px;
  word-break: "break-word";
}
.card-text {
  font-size: 13px;
  margin: 5px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-body {
  padding: 10px;
}
.card-img-top {
}
#cheader {
  margin: 0px auto;
  padding: 0px;
  background-color: #ffffff;
  width: 50%;
  border-top-left-radius: 10.5rem;
  border-top-right-radius: 10.5rem;
}
#cbodyer {
  padding: 10px;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>

<style >
#modDetail > .modal-dialog > .modal-content > .modal-body {  
  border-color: transparent;
  background-color: transparent !important;
  color: rgb(17, 16, 16);
}
#modDetail > .modal-dialog > .modal-content {
  border-color: transparent;
  background-color: transparent !important;
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #bdc0c2;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
 content: attr(data-content);
 position: relative;
 display: inline-block;
 color: #bdc0c2;
 padding: 0 0.5em;
 line-height: 1em;
 color: #010e16;
 background-color: #fcfcfa;
}
</style>





