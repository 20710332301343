<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">
      <a-spin :spinning="spinnings" tip="資料載中..."> 
      <ul>
        <li>
          <div class="mt-3" >
            <Viewper class="contantdiv" :item="list" @reloads="reloads" />
            <a-button  style="margin: 20px;" :loading="iconLoading"  @click="getmore()" type="primary" shape="round" size="large" >
              <b-icon v-show="!iconLoading" style="margin-right: 0.3rem;" scale="1.1" icon="cloud-download" variant="light" />
              取得更多資料
            </a-button>
          </div>
        </li>
      </ul>
      </a-spin>             
    </div>
  </div>
</template>
<script>
import {mapState,mapActions,mapMutations} from 'vuex';
import router from '../router'
import { joinService } from '../_services'
import * as moment from "moment/moment";
import Viewper from './panel/Viewper.vue'



const queryData = params => {
  return joinService.finddata(params.page,params.size,params.id,params.mods) 
};

export default {
  name: 'Myactsline',
  components: {
    Viewper,
  }, 
  props: {
    msg: String
  },
  data() {
    return {
      spinnings: true, 
      windowsize: {
            width: 0,
            height: 0
        }, 
      page: 1,       
      classtheme:null,
      iconLoading:false,
      list:[],     
      uId:'',
    };
  }, 
  computed: {
    ...mapState("account", ["status", "user","linecheck"]),
  },   
  mounted(){
        if(this.user==null){
          router.replace('/')
        }else{
          this.checktoken()   
          this.fetch();       
        }  
  },
  methods: {
    ...mapActions('account',['logout','checktoken']), 
    ...mapMutations('account',['setpage','getLinecheck']),   
    query(rs){
      this.classtheme = rs
    },

    orgint(){
        let obj= {id:this.$route.name}
        this.setpage(obj)
        this.getLinecheck()
        if(this.linecheck==false){
          this.$refs["con1"].showline();
        }else{
          this.uId = this.user.data.user.id
          this.fetch();                 
        }  
    },

    reloads(rs){
      for (let i = 0; i < this.list.length; i++) {
        if(this.list[i].id==rs.id){
          this.list[i] =rs
          break
        }
      }      
    },

    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },
    getmore(){
      this.fetch()
    },             
    fetch(
      params = {
          page: this.page,
          size: 3,
          id:this.user.data.user.id,
          mods:'N'                  
      }) {
      queryData({
        ...params,
      }).then(({ data }) => {
            let obj = data.data
            if (obj!=null) {
                this.page += 1;                
                setTimeout(() => {
                  this.list.push(...obj);
                  this.iconLoading=false  
                }, 100);

            }else{
              this.iconLoading=false
            }
            this.spinnings=false;
      });
    },

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    }
  },      
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 0px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}
.contantdiv{
  margin: 5px;
  overflow-x: hidden;
}


</style>

