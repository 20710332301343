<template>
  <div class="overflow-hidden"  >
    <div class="d-flex flex-column min-vh-100">
      <div class="lineHeader">
        <a-button type="link" @click="toNext()"  class="fontstyle">
          <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="chevron-left" variant="light" />
          回上一頁
        </a-button>      
      </div>      
        <ul>
          <li>
            <div class="text-center" id="itemlist" >   
                <div style="padding:1.7rem 0 0 0;" />   
                <Myactsline v-if="!showPanel"  />
            </div>
          </li>
        </ul>           
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import Myactsline from '@/myacts/Myactsline.vue'
import router from '../router'

export default {
  name: 'Mylove',
  components: {
    Myactsline,
    //Schedule
  }, 
  props: {
    msg: String
  },
  data() {
    return {  
      showTop: false,   
      showPanel : false,             
      aclove:[],   
      message:'',
      hides:null,
    };
  },  
  mounted(){
  },
  computed: {
    ...mapState("account", ["user"]),
  },    
  methods: {

    toNext(){
      router.replace({ name: 'Myline'})
      //router.go(-1)
    },   
    toShow(){
      this.hides=='johnny123321'?this.showPanel=!this.showPanel:this.showPanel=false
    }
  },    
}
</script>
<style scoped>
#itemlist {
    margin:0px auto;
    padding:0px;
    max-width: 1024px;  
    text-align:left;
 }

  img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
    display: block;
    clip-path: polygon(-4px -4px, 100.4% -3px, 99.2% 102.57%, -0.8% 102.97%);
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    width: min(98%, 500px);
    margin: 0 auto;
    box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
    overflow: hidden;
  }

  .cards-details {
    position: relative;
    padding: 0 0.05rem 0 0.5rem;
  }  


ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 2.3rem;
   padding: 0.2rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}

.fontstyle{
   font-size:0.8rem;
   color:#faf8f8;
}

.applyfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  color: #f0f8f8;
  text-align: center;
  z-index: 7;
}
</style>

